import { reactive } from "vue";

const useValidateRules = () => {
  // 登录界面
  const loginRules = reactive({
    account: [{ required: true, message: "账号不能为空" }],
    password: [{ required: true, message: "密码不能为空" }],
  });

  const sysUserRules = reactive({
    account: [{ required: true, message: "账号不能为空" }],
    name: [{ required: true, message: "姓名不能为空" }],
    password: [{ required: true, message: "密码不能为空" }],
    roleId: [{ required: true, message: "角色不能为空" }],
  });

  const sysRoleRules = reactive({
    name: [{ required: true, message: "名称不能为空" }],
  });

  const parkingCurbRules = reactive({
    name: [{ required: true, message: "车场名称不能为空" }],
    code: [{ required: true, message: "车场编码不能为空" }],
    total: [{ required: true, message: "总车位数不能为空" }],
    status: [{ required: true, message: "请选择车场状态" }],
    parkingLotBillRuleId: [{ required: true, message: "收费规则不能为空" }],
    address: [{ required: true, message: "详细地址不能为空" }],
    lngLat: [{ required: true, message: "经度纬度不能为空" }],
  });

  const parkingCloseRules = reactive({
    name: [{ required: true, message: "车场名称不能为空" }],
    ltdCode: [{ required: true, message: "企业编码不能为空" }],
    code: [{ required: true, message: "车场编码不能为空" }],
    total: [{ required: true, message: "总车位数不能为空" }],
    status: [{ required: true, message: "请选择车场状态" }],
    parkingLotBillRuleId: [{ required: false }],
    address: [{ required: true, message: "详细地址不能为空" }],
    lngLat: [{ required: true, message: "经度纬度不能为空" }],
  });

  const parkingBillRuleRules = reactive({
    name: [{ required: true, message: "收费规则名称不能为空" }],
    parkingFreeTime: [{ required: true, message: "收费规则名称不能为空" }],
    dayFeeMax: [{ required: true, message: "收费规则名称不能为空" }],
    leaveFreeTime: [{ required: true, message: "收费规则名称不能为空" }],
    status: [{ required: true, message: "收费规则名称不能为空" }],
    dayTimeSection: [{ required: true, message: "收费规则名称不能为空" }],
  });
  const refundVoRules = reactive({
    refundAmount: [{ required: true, message: "退款金额不能为空" }],
    reason: [{ required: true, message: "退款原因不能为空" }],
  });
  const specialPlateVoRules = reactive({
    plateNumber: [{ required: true, message: "车牌号不能为空" }],
  });

  const pdaRules = reactive({
    name: [{ required: true, message: "pda设备名称不能为空" }],
    code: [{ required: true, message: "pda编号不能为空" }],
    /*sn:   [{ required: true, message: "机器码不能为空" }],*/
    state: [{ required: true, message: "请选择pda设备状态" }],
  });

  const geomagneticVoRules = reactive({
    name: [{ required: true, message: "设备名称不能为空" }],
    sn: [{ required: true, message: "机器码不能为空" }],
    parkingLotId: [{ required: true, message: "请选择车场" }],
    parkingPlaceId: [{ required: true, message: "请选择车位" }],
  });

  const parkingPlaceVoRules = reactive({
    code: [{ required: true, message: "泊位编号不能为空" }],
    parkingLotId: [{ required: true, message: "请选择车场" }],
  });

  const pdaUserVoRules = reactive({
    name: [{ required: true, message: "人名姓名不能为空" }],
    code: [{ required: true, message: "用户编号不能为空" }],
    phone: [{ required: true, message: "联系方式不能为空" }],
    account: [{ required: true, message: "终端登录账号不能为空" }],
    password: [{ required: true, message: "终端登录密码不能为空" }],
    pdaId: [{ required: true, message: "请选择PDA" }],
    parkingLotIds: [{ required: true, message: "请选择车场" }],
    state: [{ required: true, message: "请选择人员状态" }],
  });
  const couponVipVoRules = reactive({
    couponPackageName: [{ required: true, message: "优惠券名称不能为空" }],
    couponType: [{ required: true, message: "请选择优惠券类型" }],
    parkTYpe: [{ required: true, message: "请选择车场类型" }],
    couponValue: [{ required: true }],
    publishNum: [
      {
        required: true,
        validator: async (_rule, value) => {
          if (!value) {
            return Promise.reject("发行总量不能为空");
          }
          if (Number.parseInt(value) > 100000) {
            return Promise.reject("发行总量不能超过100000");
          }
          return Promise.resolve();
        },
      },
    ],
    effectiveDay: [
      {
        validator: async (rule, value) => {
          if (!rule.required) {
            return Promise.resolve();
          }
          if (!value) {
            return Promise.reject("有效天数不能为空");
          }
          if (Number.parseInt(value) > 1000) {
            return Promise.reject("超过最大限制1000天");
          }
          return Promise.resolve();
        },
      },
    ],
    pubDate: [{ required: true }],
    useDate: [{ required: true, message: "有效时段不能为空" }],
  });

  const monthlyRentalPackageRules =reactive({
    packageName: [{ required: true, message: "套餐名称不能为空" }],
    packagePayAmount: [{ required: true, message: "套餐金额不能为空" }],
    packageType: [{ required: true, message: "请选择套餐类型" }],
    parkingLotId: [{ required: true, message: "请选择车场" }],
  });

  return {
    loginRules,
    sysUserRules,
    sysRoleRules,
    parkingCurbRules,
    parkingCloseRules,
    parkingBillRuleRules,
    refundVoRules,
    specialPlateVoRules,
    geomagneticVoRules,
    parkingPlaceVoRules,
    pdaRules,
    pdaUserVoRules,
    couponVipVoRules,
    monthlyRentalPackageRules,
  };
};
export default useValidateRules;
